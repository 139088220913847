export default function (instance) {
  const urlPrefix = '/team';

  return {
    getList: async function (params) {
      return await instance.get(`${urlPrefix}/`, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    getEmployeeById: async function (id) {
      return await instance.get(`${urlPrefix}/subordinates/${id}`);
    },
    getFilterAreas: async function ({ limit = 100, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/areas`, {
        params: { limit, offset },
      });
    },
    getFilterLegalEntity: async function ({ limit = 100, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/legal_entities`, {
        params: { limit, offset },
      });
    },
    getFilterUnits: async function ({ limit = 100, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/units`, {
        params: { limit, offset },
      });
    },
    getFilterUsers: async function ({ search = '', limit = 100, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/users`, {
        params: { limit, offset, search },
      });
    },
    getFilterContractTypes: async function ({ limit = 100, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/contract_types`, {
        params: { limit, offset },
      });
    },
    getSubordinatesAbsences: async function (params) {
      return await instance.get(`${urlPrefix}/absences`, {
        params: params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    getProbations: async function (params) {
      return await instance.get(`${urlPrefix}/probations/`, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    approveRequest: async function ({ id, comment }) {
      return await instance.post(`${urlPrefix}/probations/${id}/approve`, {
        comment,
      });
    },
    rejectRequest: async function ({ id, comment }) {
      return await instance.post(`${urlPrefix}/probations/${id}/reject`, {
        comment,
      });
    },
    getProbationById: async function (id) {
      return await instance.get(`${urlPrefix}/probations/${id}`);
    },
    editProbationById: async function (id, params) {
      return await instance.put(`${urlPrefix}/probations/${id}`, params);
    },
    getFilterProbationAreas: async function ({ limit = 200, offset = 0 }) {
      return await instance.get(`${urlPrefix}/probations/filters/areas`, {
        params: { limit, offset },
      });
    },
    getFilterProbationLegalEntity: async function ({
      limit = 200,
      offset = 0,
    }) {
      return await instance.get(
        `${urlPrefix}/probations/filters/legal_entities`,
        {
          params: { limit, offset },
        }
      );
    },
    getFilterProbationUnits: async function ({ limit = 200, offset = 0 }) {
      return await instance.get(`${urlPrefix}/probations/filters/units`, {
        params: { limit, offset },
      });
    },
    approveMassTasks: async function (ids) {
      return await instance.post(`${urlPrefix}/probations/approve`, {
        ids,
      });
    },
    rejectMassTasks: async function (ids) {
      return await instance.post(`${urlPrefix}/probations/reject`, {
        ids,
      });
    },
    getFilterProbationContractTypes: async function ({
      limit = 200,
      offset = 0,
    }) {
      return await instance.get(
        `${urlPrefix}/probations/filters/contract_types`,
        {
          params: { limit, offset },
        }
      );
    },
    getCounts: async function () {
      return await instance.get(`${urlPrefix}/meta`);
    },
  };
}
