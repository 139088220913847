export default function (instance) {
  const urlPrefix = '/external_contractors';

  return {
    getList: async function (params) {
      return await instance.get(`${urlPrefix}/`, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    blockEmployee: async function (params) {
      return await instance.post(`${urlPrefix}/block`, params);
    },
    extendAccessEmployee: async function (id) {
      return await instance.post(`${urlPrefix}/extend_access`, {
        uid: id,
      });
    },
    updateManager: async function (params) {
      return await instance.post(`${urlPrefix}/update_manager`, params);
    },
    terminateContract: async function (id) {
      return await instance.post(`${urlPrefix}/terminate_contract`, {
        uid: id,
      });
    },
    getFilterContractors: async function ({
      search = '',
      limit = 10,
      offset = 0,
    }) {
      return await instance.get(`${urlPrefix}/filters/contractor`, {
        params: { limit, offset, search },
      });
    },
    getFilterSapNumbers: async function ({
      search = '',
      limit = 10,
      offset = 0,
    }) {
      return await instance.get(`${urlPrefix}/filters/sap_number`, {
        params: { limit, offset, search },
      });
    },
    getFilterManagers: async function ({
      search = '',
      limit = 10,
      offset = 0,
    }) {
      return await instance.get(`${urlPrefix}/filters/manager`, {
        params: { limit, offset, search },
      });
    },
    getFilterLegalEntities: async function ({
      search = '',
      limit = 10,
      offset = 0,
    }) {
      return await instance.get(`${urlPrefix}/filters/legal_entities`, {
        params: { limit, offset, search },
      });
    },
    getFilterUnits: async function ({ search = '', limit = 10, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/units`, {
        params: { limit, offset, search },
      });
    },
    getFilterContractTypes: async function ({ limit = 10, offset = 0 }) {
      return await instance.get(`${urlPrefix}/filters/contract_types`, {
        params: { limit, offset },
      });
    },
    getInfo: async function () {
      return await instance.post(`${urlPrefix}/meta`);
    },
  };
}
