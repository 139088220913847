export default {
  // permissions
  PERMISSION: {
    TRIP: 'IAM_TRIPS_SUPERUSER',
    VACATION: 'IAM_VACATIONS_SUPERUSER',
    PROBATION: 'IAM_PROBATIONS_SUPERUSER',
    COMPENSATION: 'IAM_COMPENSATIONS_SUPERUSER',
    CONTRACTORS: 'IAM_CONTRACTORS_SUPERUSER',
  },

  // vacations
  TYPE_VACATION: {
    PAID: 0,
    NOT_PAID: 1,
    ABSENCE: 2,
    ABSENCE_WITHOUT_PAY: 3,
    ADDITIONAL: 4,
  },
  STATUS_VACATION: {
    AGREEMENT: 0,
    AGREED: 1,
    ON_PERSONNEL: 2,
    KEDO_SIGN: 3,
    PAPER_SIGN: 4,
    ERROR_ERP: 5,
    SIGN: 6,
    COMPLETED: 7,
    REJECTED: -1,
    KEDO_SIGN_COMPANY: 8,
  },
  STATUS_USER_VACATION: {
    AUTHOR: 0,
    LEADER: 1,
    RESOURCES_DEPARTAMENT: 2,
    HIDDEN: '-1',
  },

  // business trips
  TYPE_TRIP: {
    BUSINESS_TRIP: 1,
    BUSINESS_TRIP_CONFERENCE: 2,
    CONFERENCE: 3,
  },
  STATUS_TRIP: {
    DRAFT: 1, // в режиме редактирования, не отправлена
    APPROVING: 2, // на согласовании (первые этапы до HR BP)
    PROCESSING: 3, // в работе (после HR BP)
    APPROVED: 4, // согласована (можно ехать)
    COMPLETED: 5, // завершена (когда travel получает отчетные документы)
    DECLINED: 6, // отклонена
    ARCHIVED: 7, // в архиве
  },
  STATUS_APPROVAL: {
    UNKNOWN: 0,
    PROCESSING: 1,
    APPROVED: 2,
    DECLINED: 3,
    SKIPPED: 4,
  },
  TYPE_USER_APPROVAL: {
    FUNCTIONAL_MANAGER: 1,
    LINE_MANAGER: 2,
    CFO: 3,
    HR_BP: 4,
    LAWYERS: 5,
    TRAVEL: 6,
    PERSONNEL: 7,
    PR: 8,
    ACCOUNTING: 9,
    ACCOUNTING_CALLBACK: 10,
    TRAVEL_CALLBACK: 11,
    ADMINISTRATIVE_DIRECTOR: 12,
    TRAVEL_BUDGET_CHECK: 13,
    INITIATOR_REPORT: 14,
    INITIATOR_REVISION: 15,
    OPERATIONS_DIRECTOR: 16,
  },
  TYPE_DOCUMENTS: {
    INTERNAL_PASSPORT: 1, // паспорт РФ
    INTERNATIONAL_PASSPORT: 2, // загранпаспорт
    VISA: 3, // виза
    TRAVEL_PASSPORT: 4, // универсальный загранпаспорт/паспорт других стран
    OTHER: 5, // любой документ
  },
  CURRENCY: {
    RUB: 'RUB',
    USD: 'USD',
    EUR: 'EUR',
  },
  EXTERNAL_TRIP_STATUS: {
    KEDO_SIGN: 3,
    PAPER_SIGN: 4,
    SIGN: 6,
    COMPLETED: 7,
    REJECTED: -1,
    KEDO_SIGN_COMPANY: 8,
  },

  // teams
  PROBATION_STATUS: {
    AWAITING_DECISION: 0, // ожидает решения
    IN_PROGRESS: 10,
    IN_PROGRESS_HR_HELP: 15,
    COMPLETED: 20,
    DISMISSED: 25,
    REJECTED: 30,
    OUTDATED: 40,
    MANUAL_RESOLUTION: 60,
  },

  // admin
  SERVICE_TYPE: {
    TRIP: 1,
    VACATION: 2,
    PROBATION: 3,
    COMPENSATION: 4,
  },

  // compensations
  COMPENSATION_TYPE: {
    SPORT: 0,
  },

  COMPENSATION_STATUS: {
    DRAFT: 0,
    IN_PROGRESS: 10,
    REVISION: 20,
    COMPLETED: 30,
    REJECTED: 40,
    DELETED: 50,
  },
};
